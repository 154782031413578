<template>
  <div class="pageHeader--bottom" v-bind:class="{ open: mobileNav }">
    <div class="container">
      <nav
        class="mainNav collapse"
        v-bind:class="{
          open: mobileNav,
        }"
      >
        <ul
          class="blanklist"
          v-bind:class="{
            hideLvl1: nextLevel,
            hasMenuLinkIcon: hasMenuLinkIcon,
          }"
        >
          <li
            class="hasSub responsive-head"
            v-for="(item, index) in data"
            :class="{
              open: nextLevel,
              menuLinkIcon: item.menuLinkIcon,
              openedNav: isOpen !== '',
              hovered: hoveredItem === index,
            }"
            v-on:mouseover="() => handleMouseEnter(index)"
            v-on:mouseleave="() => handleMouseLeave(index)"
          >
            <div
              class="d-flex justify-content-between w-100"
              style="z-index: 10"
              :class="{ 'd-none': isOpen }"
            >
              <T3Link
                class="responsive-font-size header-main-link"
                v-if="!item.menuLinkIcon"
                :href="item.link"
                :class="{ 'text-primary active': item.current || item.active }"
              >
                <template #content>
                  {{ item.title }}
                </template>
              </T3Link>
              <div v-else class="d-flex align-items-center favourite-hide">
                <div class="menuLinkIcon">
                  <T3Link class="btn btn-pill btn-icon" :href="item.link">
                    <template #content>
                      <span>
                        <i :class="getIcon(item.menuLinkIcon)"></i>
                        {{ item.title }}
                      </span>
                    </template>
                  </T3Link>
                </div>
              </div>
              <T3Link
                v-if="item.menuLinkIcon"
                class="d-lg-none"
                :href="item.link"
              >
                <template #content>
                  <span :class="getIcon(item.menuLinkIcon)"> </span>
                  {{ item.title }}
                </template>
              </T3Link>

              <button
                v-if="item.children"
                class="nextLevel d-flex d-lg-none"
                :class="
                  isOpen === item.title
                    ? 'icon-chevron-down'
                    : 'icon-chevron-right'
                "
                @click="
                  $emit('openDropDown', isOpen === item.title ? '' : item.title)
                "
              ></button>
            </div>
            <template v-if="index == 0">
              <ul
                class="megaNav blanklist"
                v-bind:class="{ open: isOpen === item.title }"
              >
                <li class="container container-mobile-padding">
                  <div class="d-lg-flex py-lg-2">
                    <ul
                      class="blanklist content col-lg-8 ps-0 product-list-content"
                    >
                      <li
                        class="col-xs-12 me-lg-7"
                        v-for="(child, idx) in getList(index, item.children)"
                      >
                        <ul
                          v-if="child.length"
                          v-for="subChild in getchild(child)"
                          class="blanklist"
                          :class="
                            subChild.parentTitle == showChild ||
                            !subChild.isChild
                              ? ''
                              : 'd-sm-none d-md-none d-lg-block'
                          "
                        >
                          <li class="border-list">
                            <T3Link
                              :href="subChild.link"
                              :class="getClass(idx, subChild)"
                              @click.native="closeMenu(index)"
                              class="py-2"
                            >
                              <template #content>
                                <strong class="sub-container">
                                  <span
                                    class="break-text"
                                    :class="subChild.isChild && 'light-text'"
                                    >{{ subChild.title }}</span
                                  >
                                  <button
                                    @click.prevent="
                                      showChildHandle(
                                        showChild == subChild.title
                                          ? ''
                                          : subChild.title,
                                        $event,
                                      )
                                    "
                                    v-if="subChild.hasSubpages"
                                    class="d-flex d-lg-none nextLevel sad"
                                    :class="
                                      subChild.title == showChild
                                        ? 'icon-chevron-down'
                                        : 'icon-chevron-right'
                                    "
                                  />
                                </strong>
                              </template>
                            </T3Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div
                      class="col-lg-3 col-sm-12 col-md-6 nav_product_promo"
                      v-if="item.menuCards"
                    >
                      <div
                        class="menu-cards-area"
                        v-for="menu in getMenuCardSec(item.menuCards)"
                      >
                        <T3Link
                          class="menu-card-links"
                          :href="menu.link.href"
                          @click.native="closeMenu(index)"
                          :class="{
                            'text-primary active': menu.current || menu.active,
                          }"
                        >
                          <template #content>
                            <div class="cards">
                              <figure class="menu-card-image">
                                <RatioImage
                                  :elemClass="'ratio-5x4 m-0 product-image'"
                                  :imgSrc="getImage(menu.image)"
                                  :imgAlt="getImageAlt(menu.image)"
                                />
                              </figure>
                              <div class="d-flex justify-content-between mt-1">
                                <strong class="text-uppercase">{{
                                  menu.headline
                                }}</strong>
                                <i class="icon-arrow-right"></i>
                              </div>
                              <p>{{ menu.text }}</p>
                            </div>
                          </template>
                        </T3Link>
                      </div>
                    </div>
                  </div>

                  <ul
                    class="bottom-buttons buttons-bottom-area blanklist justify-content-center"
                    v-if="getHtml(item.menuBottomCta)"
                  >
                    <li
                      class="d-lg-flex justify-content-center"
                      v-html="getHtml(item.menuBottomCta)"
                    />
                  </ul>
                </li>
              </ul>
            </template>

            <template v-if="index == 1 || index == 5">
              <ul
                class="megaNav blanklist"
                v-bind:class="{ open: isOpen === item.title }"
                v-show="!loading"
              >
                <li class="container container-mobile-padding">
                  <ul class="blanklist content">
                    <li class="row">
                      <ul
                        v-for="item in spliceList(item.children)"
                        class="blanklist col-lg-6 col-xs-12"
                      >
                        <li v-for="child in item">
                          <T3Link
                            :href="child.link"
                            :class="{
                              'text-primary active':
                                child.current || child.active,
                            }"
                            @click.native="closeMenu(index)"
                            v-if="child.link != '#logoutVSF'"
                          >
                            <template #content>
                              <strong>{{ child.title }}</strong>
                            </template>
                          </T3Link>
                          <button
                            class="btn btn-primary"
                            @click="logoutHandling"
                            v-else
                          >
                            {{ child.title }}
                          </button>
                        </li>
                      </ul>

                      <div class="col-lg-6 col-xs-12" v-if="item.menuCards">
                        <div class="row">
                          <div
                            class="col-xl-6 col-sm-12 col-md-6 col-xs-12 menu-cards-area"
                            v-for="menu in getMenuCardSec(item.menuCards)"
                          >
                            <T3Link
                              class="menu-card-links"
                              :href="menu.link.href"
                              @click.native="closeMenu(index)"
                              :class="{
                                'text-primary active':
                                  menu.current || menu.active,
                              }"
                            >
                              <template #content>
                                <div class="cards">
                                  <figure class="menu-card-image">
                                    <img
                                      v-lazy-load :data-src="getImage(menu.image)"
                                      :alt="getImageAlt(menu.image)"
                                    />
                                  </figure>
                                  <div
                                    class="d-flex justify-content-between mt-1"
                                  >
                                    <strong class="text-uppercase">{{
                                      menu.headline
                                    }}</strong>
                                    <i class="icon-arrow-right"></i>
                                  </div>
                                  <p>{{ menu.text }}</p>
                                </div>
                              </template>
                            </T3Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul
                    class="bottom-buttons buttons-bottom-area blanklist justify-content-center"
                    v-if="getHtml(item.menuBottomCta)"
                  >
                    <li
                      class="d-lg-flex text-center justify-content-center"
                      v-html="getHtml(item.menuBottomCta)"
                    />
                  </ul>
                </li>
              </ul>
            </template>

            <template v-if="index == 3 || index == 4">
              <ul
                class="megaNav blanklist"
                v-bind:class="{ open: isOpen === item.title }"
                v-show="!loading"
              >
                <li class="container container-mobile-padding">
                  <ul class="blanklist content">
                    <li class="row">
                      <div class="col-lg-6 col-xs-12">
                        <ul
                          v-for="item in spliceList(item.children)"
                          class="blanklist"
                        >
                          <li v-for="child in item">
                            <T3Link
                              :href="child.link"
                              @click.native="closeMenu(index)"
                              :class="{
                                'text-primary active':
                                  child.current || child.active,
                              }"
                              v-if="child.link != '#logoutVSF'"
                            >
                              <template #content>
                                <strong>{{ child.title }}</strong>
                              </template>
                            </T3Link>
                            <button
                              class="btn btn-primary"
                              @click="logoutHandling"
                              v-else
                            >
                              {{ child.title }}
                            </button>
                          </li>
                        </ul>
                      </div>

                      <template v-if="item.menuCards">
                        <div class="col-lg-6 col-xs-12">
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-sm-12 col-xs-12 menu-cards-area"
                              v-for="menu in getMenuCardSec(item.menuCards)"
                            >
                              <T3Link
                                class="menu-card-links"
                                :href="menu.link.href"
                                @click.native="closeMenu(index)"
                                :class="{
                                  'text-primary active':
                                    menu.current || menu.active,
                                }"
                              >
                                <template #content>
                                  <div class="cards">
                                    <figure class="menu-card-image">
                                      <img
                                        v-lazy-load :data-src="getImage(menu.image)"
                                        :alt="getImageAlt(menu.image)"
                                      />
                                    </figure>
                                    <div
                                      class="d-flex justify-content-between mt-1"
                                    >
                                      <strong class="text-uppercase">{{
                                        menu.headline
                                      }}</strong>
                                      <i class="icon-arrow-right"></i>
                                    </div>
                                    <p>{{ menu.text }}</p>
                                  </div>
                                </template>
                              </T3Link>
                            </div>
                          </div>
                        </div>
                      </template>
                    </li>
                  </ul>

                  <ul
                    class="bottom-buttons buttons-bottom-area text-center blanklist justify-content-center"
                    v-if="getHtml(item.menuBottomCta)"
                  >
                    <li
                      class="d-lg-flex justify-content-center"
                      v-html="getHtml(item.menuBottomCta)"
                    ></li>
                    <li v-if="index == 3 && !isAuthenticated">
                      <button
                        type="button"
                        @click="getProLoginLink()"
                        class="btn btn-pill"
                      >
                        {{ $t('LoginRegister') }}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>

            <template v-if="index == 2">
              <ul
                class="megaNav blanklist"
                v-bind:class="{ open: isOpen === item.title }"
                v-show="!loading"
              >
                <li class="container container-mobile-padding">
                  <ul class="blanklist content">
                    <li class="row">
                      <div
                        class="col-xl-3 col-md-6 col-lg-4 col-sm-12 col-xs-12 menu-cards-area"
                        v-for="child in item.menuCards"
                      >
                        <T3Link
                          :href="child.link.href"
                          class="menu-card-links"
                          @click.native="closeMenu(index)"
                          :class="{
                            'text-primary active':
                              child.current || child.active,
                          }"
                        >
                          <template #content>
                            <div class="cards">
                              <figure class="menu-card-image">
                                <img
                                  v-lazy-load :data-src="getImage(child.image)"
                                  :alt="getImageAlt(child.image)"
                                />
                              </figure>
                              <div class="d-flex justify-content-between mt-1">
                                <div class="d-flex gap-1">
                                  <i
                                    v-if="child.menuCardIcon"
                                    :class="getIcon(child.menuCardIcon)"
                                  ></i>
                                  <strong class="text-uppercase">{{
                                    child.headline
                                  }}</strong>
                                </div>
                                <i class="icon-arrow-right"></i>
                              </div>
                              <div
                                v-if="child.blog_date"
                                class="d-flex justify-content-between my-1 read-info"
                              >
                                <span>{{ child.blog_date }}</span>
                                <span>{{ child.blog_read_time }}</span>
                              </div>
                              <p>{{ child.text }}</p>
                            </div>
                          </template>
                        </T3Link>
                      </div>
                    </li>
                  </ul>

                  <ul
                    class="bottom-buttons buttons-bottom-area text-center blanklist justify-content-center"
                    v-if="getHtml(item.menuBottomCta)"
                  >
                    <li
                      class="d-lg-flex justify-content-center"
                      v-html="getHtml(item.menuBottomCta)"
                    />
                  </ul>
                </li>
              </ul>
            </template>
          </li>
        </ul>

        <ul
          class="blanklist mainNav--controls d-none d-lg-flex"
          v-bind:class="{ 'd-none': nextLevel }"
        >
          <li class="d-flex align-items-center">
            <span class="me-2">
              <SearchOverlay />
            </span>
          </li>
          <li>
            <a class="icon-user" href="#" @click.prevent="handleAccountClick">
              <span class="d-none">Account</span>
            </a>
          </li>
          <li>
            <a
              class="icon-shopping-bag"
              href="#"
              @click.prevent="toggleCartSidebar()"
            >
              <span class="d-none">Basket</span>
              <span v-if="cartTotalItems" class="sf-badge--number cart-badge">
                {{ cartTotalItems }}
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { useMainNavStore } from '@/stores/mainNavStore';
import { useLanguageStore } from '@/stores/languageStore';
import {
  watch,
  onBeforeMount,
  onMounted,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';

import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import SearchOverlay from '~/components/SearchOverlay.vue';

import { useUiState } from '~/composables';
import T3Link from '~/components/General/T3Link.vue';
import RatioImage from '~/components/RatioImage.vue';

export default {
  name: 'MainNav',
  props: {
    mobileNav: Boolean,
    nextLevel: Boolean,
    isOpen: String,
    nextLevelFunction: Function,
    openDropDown: Function,
  },
  components: {
    RatioImage,
    T3Link,
    SearchOverlay,
  },

  setup() {
    const languageStore = useLanguageStore();
    const loading = computed(() => languageStore.loading);
    const mainNavStore = useMainNavStore();
    const { toggleCartSidebar, toggleLoginModal } = useUiState();
    const {
      app: { i18n },
      localeRoute,
    } = useContext();
    const { app } = useContext();
    const locale = i18n.localeProperties.code;
    const router = useRouter();
    const { logout } = useUser();
    const { isAuthenticated } = useUser();

    const { loadTotalQty: loadCartTotalQty, cart, clear } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();

    const logoutHandling = async () => {
      await logout({});
      await clear({});
      await router.push(localeRoute({ name: 'home' }));
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    const getProLoginLink = async () => {
      handleAccountClick();
      mainNavStore.updateShowDocCheckLogin(true);
    };

    onBeforeMount(async () => {
      if (cart?.value !== null) {
        await loadCartTotalQty();
      }
      // eslint-disable-next-line promise/catch-or-return
      // await loadWishlistItemsCount();
    });

    let data = computed(() => mainNavStore.mainMenuItems);

    const hasMenuLinkIcon = computed(() => {
      for (let i = 0; i < data.value.length; i++) {
        const item = data.value[i];
        if (item.menuLinkIcon) {
          return true;
        }
      }
      return false;
    });

    return {
      toggleLoginModal,
      toggleCartSidebar,
      data,
      hasMenuLinkIcon,
      loading,
      showChild: '',
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      logoutHandling,
      getProLoginLink,
      handleAccountClick,
      isAuthenticated: isAuthenticated.value,
    };
  },

  data() {
    return {
      hoverTimeouts: {},
      hoveredItem: null,
    };
  },

  methods: {
    getList(index, list) {
      if (index == 0 && list?.length) {
        let newArray = [];
        let newChildren = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].title == 'Divider') {
            newArray.push(JSON.parse(JSON.stringify(newChildren)));
            newChildren = [];
          } else {
            newChildren.push(list[i]);
          }
        }
        newArray.push(JSON.parse(JSON.stringify(newChildren)));
        return newArray;
      }
      return [];
    },

    spliceList(list) {
      let chunk = [];
      for (var i = 0, len = list?.length; i < len; i += 9) {
        chunk.push(list?.slice(i, i + 9));
      }
      return chunk;
    },

    getchild(list) {
      let tempArr = [];
      list.map((item) => {
        if (item?.children?.length) {
          tempArr = [
            ...list,
            ...item.children.map((d) => ({
              ...d,
              isChild: true,
              parentTitle: item.title,
            })),
          ];
        } else {
          tempArr = list;
        }
      });

      return tempArr;
    },

    showChildHandle(value, event) {
      event.stopPropagation();
      this.showChild = value;
    },

    getMenuCardFirst(list) {
      if (list?.length) {
        return list.slice().splice(0, 1);
      } else {
        return [];
      }
    },

    getMenuCardSec(list) {
      if (list?.length) {
        return list.slice();
      } else {
        return [];
      }
    },

    getMenuBottomCTA() {
      return;
    },

    getHtml(html) {
      if (html?.length) {
        return html[0].menu_bottom_cta;
      } else {
        return '';
      }
    },
    getIcon(icons) {
      if (icons?.length) {
        return icons[0].icon_class_name;
      } else {
        return '';
      }
    },
    getImage(list) {
      if (list?.length) {
        if (list[0].properties?.originalUrl?.includes('.webp')) {
          return list[0].properties?.originalUrl;
        }
        return list[0]?.publicUrl;
      } else {
        return '';
      }
    },
    getImageAlt(list) {
      if (list?.length) {
        if (list[0]?.properties?.alternative) {
          return list[0]?.properties?.alternative;
        } else {
          return list[0]?.properties?.filename;
        }
      }
    },

    getClass(i, item) {
      var returnClass = '';
      if (i == 0) {
        returnClass += this.getIcon(item?.menuLinkIcon);
      } else {
        returnClass += '';
      }
      if (item?.current || item?.active) {
        returnClass += 'text-primary active';
      }
      return returnClass;
    },

    handleMouseEnter(index) {
      // Clear any existing timeout for this index
      if (this.hoverTimeouts[index]) {
        clearTimeout(this.hoverTimeouts[index]);
      }

      // Set a new timeout for this index
      this.hoverTimeouts[index] = setTimeout(() => {
        this.hoveredItem = index;
      }, 200);
    },

    handleMouseLeave(index) {
      // Clear the timeout as soon as the mouse leaves the item
      clearTimeout(this.hoverTimeouts[index]);
      this.hoveredItem = null;
    },

    closeMenu(index) {
      this.isOpen = '';
      this.hoveredItem = null;
      clearTimeout(this.hoverTimeouts[index]);
    },
  },
};
</script>

<style lang="scss" scoped></style>

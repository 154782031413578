import { defineStore } from 'pinia';
import axios from 'axios';
import { getProductEndpoint } from '~/composables/utils/functions';
import { Product } from '~/modules/catalog/product/types';
import log from '@vue-storefront/nuxt/lib/src/helpers/log';

export const useMainNavStore = defineStore({
  id: 'mainNavStore',
  state: () => ({
    baseURL: process.env.T3_API_BASE,
    basePath: process.env.T3_API_PATH,
    magentoPath: process.env.VSF_MAGENTO_BASE_URL,
    mainMenuItems: [],
    languageData: [],
    footerData: [],
    customTexts: [],
    footerLowerContent: '',
    footerMeta: [],
    ctaMenu: [],
    registerPath: '',
    showDocCheckLogin: false,
    newsTicker: [],
    notificationBanner: {},
    footerLowerContentCenter: '',
    footerLowerContentRight: '',
    copyright: '',
    newsLetterLeft: '',
    newsLetterLeftTop: '',
    newsLetterRight: '',
    loading: false,
    error: null,
    isLoggedIn: false,
    searchedData: [],
    productData: [],
    productDataAll: [],
    searchLoading: false,
    searchError: false,
    mobileNav: false,
    showModal: false,
    popularSearchesData: [],
    suggetionData: [],
    suggetionProducts: [],
    doccheckVerified: '',
  }),
  getters: {
    footerArrangedData: (state) => {
      let arrangedData = [];
      state.footerData?.map((item) => {
        if (item.title != 'Fachkreise' && item.title != 'Produktdetailseiten' && item.title != 'Lohnherstellung') {
          arrangedData.push({ title: item.title, link: item.link, children: item.children || [] });
        }
      });
      return arrangedData;
    },
    searchArrangedData: (state) => {
      let result = {};
      if (state.searchedData.length && Array.isArray(state.searchedData)) {
        result = state.searchedData?.reduce(function (r, a) {
          r[a._source.type] = r[a._source.type] || [];
          r[a._source.type].push(a);
          return r;
        }, Object.create(null));
      }

      if (state.productData.length) {
        result['products'] = state.productData;
      }

      return result ? result : {};
    },
    searchTitles: (state) => {
      let searchtitles = [];
      let productTitles = [];
      if (state.suggetionData.length && Array.isArray(state.suggetionData)) {
        searchtitles = state.suggetionData.map((data) => data._source.title) || [];
      }
      if (state.suggetionProducts.length) {
        productTitles = state.suggetionProducts.map((data) => ({ title: data.title, link: data.link, type: 'product' })) || [];
      }
      const titles = [...productTitles, ...searchtitles];
      return [...new Set(titles)];
    },
  },
  actions: {
    async getNavData(locale) {
      if (locale) {
        // var country_switch = "";
        // const localeArray = locale.split("_");
        // if(localeArray[1]) {
        //     country_switch = "?country_switch="+localeArray[0];
        //     locale = localeArray[1]
        // } else {
        //     locale = localeArray[0]
        // }

        let proxyBase = '/typo3-api/';
        // const jsonPath = this.baseURL + this.basePath + locale + "/" + 'magento-content/' + '?no_cache=1' + country_switch;
        // const jsonPath = proxyBase + locale + "/" + 'magento-content/' + country_switch;
        const jsonPath = proxyBase + locale + '/' + 'magento-content/';

        this.loading = true;
        try {
          const response = await axios.get(jsonPath);
          this.mainMenuItems = response?.data?.headerData?.main_menu || [];
          this.doccheckVerified = response?.data?.headerData?.doccheck_verified || '';
          this.languageData = response?.data?.i18n || [];
          this.footerData = response?.data?.footerData?.footer_menu || [];
          this.customTexts = response?.data?.customTexts || [];

          this.footerLowerContent =
            response?.data?.footerData?.footer_lower_content[0]?.content?.structure?.rows[0]?.columns[0]?.elements[0].content?.bodytext || '';
          this.footerLowerContentCenter =
            response?.data?.footerData?.footer_lower_content[0]?.content?.structure?.rows[0]?.columns[1]?.elements[0].content?.bodytext || '';
          this.footerLowerContentRight =
            response?.data?.footerData?.footer_lower_content[0]?.content?.structure?.rows[0]?.columns[2]?.elements[0].content?.bodytext || '';
          this.footerMeta = response?.data?.footerData?.footer_meta || [];
          this.copyright = response?.data?.footerData?.footer_copyright?.replace('&copy;', 'ⓒ') || '';
          this.newsLetterLeft =
            response?.data?.footerData?.footer_newsletter[0]?.content?.structure?.rows[0]?.columns[0]?.elements[0]?.content?.bodytext || '';
          this.newsLetterLeftTop =
            response?.data?.footerData?.footer_newsletter[0]?.content?.structure?.rows[0]?.columns[0]?.elements[0]?.content?.bodytext || '';
          this.newsLetterRight =
            response?.data?.footerData?.footer_newsletter[0]?.content?.structure?.rows[0]?.columns[1]?.elements[0]?.content?.bodytext || '';
          this.ctaMenu = response?.data?.headerData?.cta_menu || [];
          this.registerPath = response?.data?.headerData?.cta_menu[2]?.link?.replace('/' + locale + '/', '') || [];
          this.notificationBanner = response?.data?.headerData?.notification_banner[0] || [];
          this.newsTicker = response?.data?.footerData?.footer_news_ticker[0]?.news || [];
          this.isLoggedIn = !!+response?.data?.headerData?.is_user_loggedin || false;
          this.productDataAll =
            response.data?.content?.find((item: { type: string }) => item.type == 'headlessproducts_productslisting')?.content?.products_list
              ?.products || [];
        } catch (error) {
          this.error = error;
        } finally {
          this.loading = false;
        }
      }
    },
    updateShowDocCheckLogin(value) {
      this.showDocCheckLogin = value;
    },
    updateMobileNav(value) {
      this.mobileNav = !value;
    },
    async searchHandler(locale: string, query: string) {
      this.searchLoading = true;
      const proxyBase = '/typo3-api/';
      const jsonPath = proxyBase + locale + `/?type=1619681085&q=${query}`;
      const productPath = proxyBase + locale + '/' + getProductEndpoint(locale);

      try {
        if (query) {
          const response = await axios.get(jsonPath);
          const productResponse = await axios.get(productPath);
          this.searchedData = response.data || [];
          const tempData =
            productResponse.data?.content?.find((item: { type: string }) => item.type == 'headlessproducts_productslisting')?.content?.products_list
              ?.products || [];

          function searchInString(item, q) {
            if (item[q].toLowerCase().includes(query?.toLocaleLowerCase())) {
              return true;
            }
          }
          function searchInObj(item, q) {
            if (item[q].filter((c) => c.title?.toLowerCase().includes(query?.toLocaleLowerCase())).length) {
              return true;
            }
          }
          function searchInProduct(item) {
            if (
              searchInString(item, 'title') ||
              searchInString(item, 'teaserText') ||
              searchInString(item, 'detailText') ||
              searchInString(item, 'searchKeywords') ||
              searchInString(item, 'searchIngredients') ||
              searchInObj(item, 'nutritions') ||
              searchInObj(item, 'categories')
            ) {
              return item;
            }
          }
          function searchForProductName(item) {
            return searchInString(item, 'title');
          }

          const productData = [...tempData?.filter(searchForProductName), ...tempData?.filter(searchInProduct)] || [];

          function removeDuplicates(a, key) {
            let seen = new Set();
            return a.filter((item) => {
              let k = key(item);
              return seen.has(k) ? false : seen.add(k);
            });
          }

          this.productData = removeDuplicates(productData, (it) => it.title) || [];
        }
      } catch (error) {
      } finally {
        this.searchLoading = false;
      }
    },
    async suggestionHandler(locale: string, query: string) {
      const proxyBase = '/typo3-api/';
      const jsonPath = proxyBase + locale + `/?type=1619681085&q=${query}`;
      const productPath = proxyBase + locale + '/' + getProductEndpoint(locale);

      try {
        if (query) {
          const response = await axios.get(jsonPath);
          const productResponse = await axios.get(productPath);
          this.suggetionData = response.data || [];
          const tempData =
            productResponse.data?.content?.find((item: { type: string }) => item.type == 'headlessproducts_productslisting')?.content?.products_list
              ?.products || [];

          function searchInString(item, q) {
            if (item[q].toLowerCase().includes(query?.toLocaleLowerCase())) {
              return true;
            }
          }
          function searchInObj(item, q) {
            if (item[q].filter((c) => c.title?.toLowerCase().includes(query?.toLocaleLowerCase())).length) {
              return true;
            }
          }
          function searchInProduct(item) {
            if (
              searchInString(item, 'title') ||
              searchInString(item, 'teaserText') ||
              searchInString(item, 'detailText') ||
              searchInString(item, 'searchKeywords') ||
              searchInString(item, 'searchIngredients') ||
              searchInObj(item, 'nutritions') ||
              searchInObj(item, 'categories')
            ) {
              return item;
            }
          }
          function searchForProductName(item) {
            return searchInString(item, 'title');
          }

          const productData = [...tempData?.filter(searchForProductName), ...tempData?.filter(searchInProduct)] || [];

          function removeDuplicates(a, key) {
            let seen = new Set();
            return a.filter((item) => {
              let k = key(item);
              return seen.has(k) ? false : seen.add(k);
            });
          }

          this.suggetionProducts = removeDuplicates(productData, (it) => it.title) || [];
        }
      } catch (error) {}
    },
    async getPopularSearch(locale: string) {
      const proxyBase = '/typo3-api/';
      const jsonPath = proxyBase + locale + `/?type=1122`;
      try {
        const response = await axios.get(jsonPath);
        this.popularSearchesData = response.data || [];
      } catch (error) {}
    },
    getProductDataFromT3(product: Product) {
      if (!product) {
        return null;
      }
      const productData = this.productDataAll.find((item) => item.magentoSku === product.sku);
      return productData;
    },
  },
});
